/* eslint-disable eqeqeq */
import React, { PureComponent } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import {
    Header,
    SidebarNav,
    PageContent,
    PageAlert,
    Page,
} from "../../../../styles";
import nav from "./nav";
import ContextProviders from "../../../../styles/components/utilities/ContextProviders";
import handleKeyAccessibility, {
    handleClickAccessibility,
} from "../../../../styles/helpers/handleTabAccessibility";
import Facilities from "../Facility/index";
import AddFacility from "../Facility/add";
import EditFacility from "../Facility/edit";
import EditFacilityCredentials from "../Facility/editCredentials";
import Roles from "../Roles/index";
import AddRole from "../Roles/add";
import EditRole from "../Roles/edit";
import AuditLog from "../Auditlog";
import HeaderNav from "../Logout";
import logoLight from "../../../../images/propio-logo-light.png";

const MOBILE_SIZE = 992;

export default class DashboardLayout extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            sidebarCollapsed: false,
            isMobile: window.innerWidth <= MOBILE_SIZE,
            showChat1: true,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        document.addEventListener("keydown", handleKeyAccessibility);
        document.addEventListener("click", handleClickAccessibility);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        if (window.innerWidth <= MOBILE_SIZE) {
            this.setState({ sidebarCollapsed: false, isMobile: true });
        } else {
            this.setState({ isMobile: false });
        }
    };

    toggleSideCollapse = () => {
        this.setState((prevState) => ({
            sidebarCollapsed: !prevState.sidebarCollapsed,
        }));
    };

    render() {
        const { sidebarCollapsed } = this.state;
        const sidebarCollapsedClass = sidebarCollapsed
            ? "side-menu-collapsed"
            : "";

        return (
            <ContextProviders>
                <div className={`app ${sidebarCollapsedClass}`}>
                    <PageAlert />
                    <div className="app-body">
                        <SidebarNav
                            nav={nav}
                            logo={logoLight}
                            isSidebarCollapsed={sidebarCollapsed}
                            toggleSidebar={this.toggleSideCollapse}
                            {...this.props}
                        />
                        <Page>
                            <Header
                                toggleSidebar={this.toggleSideCollapse}
                                isSidebarCollapsed={sidebarCollapsed}
                                {...this.props}
                            >
                                <HeaderNav />
                            </Header>
                            <PageContent>
                                <Routes>
                                    <Route
                                        path="/facilities"
                                        element={<Facilities />}
                                    />
                                    <Route
                                        path="/add-facility"
                                        element={<AddFacility />}
                                    />
                                    <Route
                                        path="/edit-facility/:facilityId"
                                        element={<EditFacility />}
                                    />
                                    <Route
                                        path="/edit-facility-credentials/:facilityId"
                                        element={<EditFacilityCredentials />}
                                    />
                                    <Route path="/roles" element={<Roles />} />
                                    <Route
                                        path="/add-role"
                                        element={<AddRole />}
                                    />
                                    <Route
                                        path="/edit-role/:roleId"
                                        element={<EditRole />}
                                    />
                                    <Route
                                        path="/auditlogs"
                                        element={<AuditLog />}
                                    />
                                    <Route
                                        exact
                                        path="*"
                                        element={
                                            <Navigate
                                                to="/admin/login"
                                                replace
                                            />
                                        }
                                    />
                                </Routes>
                            </PageContent>
                        </Page>
                    </div>
                </div>
            </ContextProviders>
        );
    }
}
