import React, { PureComponent } from "react";
import { Link, Navigate } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  CardHeader,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import {
  faRedo,
  faPlusCircle,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { axios } from "../../../../config";
import { browserStorageHelper, commonHelpers } from "../../../../helpers";
import { AlertComponent } from "../../../../components";

class AddRole extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      roleName: "",
      status: 0,
      redirectUrl: "/admin/roles",
      msg: {
        msgLoading: "",
        msgError: "",
        msgSuccess: "",
        msgWarning: "",
      },
    };
    this.renderRedirect = this.renderRedirect.bind(this);
    this.validator = new SimpleReactValidator({
      className: "text-danger",
      element: (message, className) => (
        <div className={className || "text-danger font-weight-bold"}>
          <FontAwesomeIcon icon={faExclamationTriangle} size="1x" /> {message}
        </div>
      ),
      autoForceUpdate: this,
      messages: {
        required: "Please enter a valid :attribute.",
      },
    });
  }

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const self = this;
    let { roleName, status } = this.state;
    if (this.validator.allValid()) {
      self.setState({
        msg: commonHelpers.setMsg("Adding facility...", "", "", ""),
      });
      const token = browserStorageHelper.getItem("token");
      const userId = browserStorageHelper.getItem("uid");
      const postData = {
        user_id: userId,
        role_name: roleName.trim(),
        status: parseInt(status)
      };

      const axiosOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/admin/provider/role`,
          postData,
          axiosOptions
        )
        .then((res) => {
          self.setState({
            msg: commonHelpers.setMsg(
              "",
              "",
              "Role created successfully.",
              ""
            ),
          });
          const redirect = true;
          const redirectUrl = self.state.redirectUrl;
          self.setState({ redirect, redirectUrl });
        })
        .catch((err) => {
          self.setState({
            msg: commonHelpers.errMessage(err),
          });
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  renderRedirect = () => {
    const { redirect, redirectUrl } = this.state;
    if (redirect) {
      return <Navigate to={redirectUrl} />;
    }
  };

  render() {
    const { roleName, status, msg } = this.state;
    return (
      <div>
        {this.renderRedirect()}
        <div className="container-fluid mt-3">
          <Row className="py-3">
            <Col md="12">
              <Card>
                <CardHeader className="card-header-tab z-index-6">
                  <Row>
                    <Col size="12" lg="9" className="mb-2 font-weight-bold">
                      Add New Role
                    </Col>
                    <Col size="12" lg="3" className="mb-2">
                      <Link to="/admin/roles">
                        <Button
                          className="mb-2 mr-2 btn-square btn-gradient-success backBtn"
                          style={{ float: "right" }}
                        >
                          <FontAwesomeIcon icon={faRedo} className="fa-fw" />{" "}
                          Back
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col size="12" lg="12" className="mb-2">
                      <Form
                        name="frmAddRole"
                        className="frmAddRole needs-validation"
                        id="frmAddRole"
                        onSubmit={this.handleSubmit}
                        noValidate
                      >
                        <FormGroup row>
                          <Label for="roleName" sm={2}>
                            Role Type <span className="rqfield">*</span>
                          </Label>
                          <Col sm={10}>
                            <Input
                              value={roleName}
                              name="roleName"
                              onChange={this.changeHandler}
                              type="text"
                              id="roleName"
                              placeholder="Role Type"
                              required
                            />

                            {this.validator.message(
                              "roleName",
                              roleName,
                              "required"
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="rolePermission" sm={2} mr={1}>
                            Role Permission <span className="rqfield">*</span>
                          </Label>
                          <Col sm={10}>
                            <FormGroup row>
                              <Col sm={2}>
                                <Input
                                  value={1}
                                  name="status"
                                  onChange={this.changeHandler}
                                  type="radio"
                                  id="rolePermission"
                                  className="ml-1"
                                  checked={parseInt(this.state.status) === 1}
                                  required
                                />
                                <FontAwesomeIcon
                                  icon={faExclamationTriangle}
                                  size="1x"
                                  color="red"
                                  className="ml-4"
                                />{" "}
                                <span className="text-danger">Restricted</span>
                              </Col>
                              <Col sm={2}>
                                <Input
                                  value={0}
                                  name="status"
                                  onChange={this.changeHandler}
                                  type="radio"
                                  id="rolePermission"
                                  checked={parseInt(this.state.status) === 0}
                                  required
                                />
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  size="1x"
                                  color="green"
                                />{" "}
                                <span className="text-success">Allowed</span>
                                {this.validator.message(
                                  "rolePermission",
                                  status,
                                  "required"
                                )}
                              </Col>
                            </FormGroup>
                          </Col>
                        </FormGroup>
                        <FormGroup check row>
                          <Col sm={{ size: 10, offset: 2 }}>
                            <Button className="mb-2 mr-2 btn-square btn-gradient-success btn btn-">
                              <FontAwesomeIcon
                                icon={faPlusCircle}
                                className="fa-fw"
                              />{" "}
                              Submit
                            </Button>
                          </Col>
                        </FormGroup>
                        <AlertComponent msg={msg} />
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default AddRole;
