/*
    f = open('vu languages.txt', 'r')
    lines = f.readlines()
    for l in lines:
        p = l.split('(')
        name = '('.join(p[0:-1]).strip()
        code = p[-1].strip()[:-1]
        print(f'[ "{code}" , "{name}" ],')
*/

const languages = [
    [ "ab" , "Abkhazian" ],
    [ "ach" , "Acholi" ],
    [ "adh-UG" , "Adhola (Uganda)" ],
    [ "aa" , "Afar" ],
    [ "af" , "Afrikaans" ],
    [ "af-NA" , "Afrikaans (Namibia)" ],
    [ "af-ZA" , "Afrikaans (South Africa)" ],
    [ "ak" , "Akan" ],
    [ "ak-Fant" , "Akan (Fante)" ],
    [ "ak-Fant-GH" , "Akan (Fante, Ghana)" ],
    [ "ak-GH" , "Akan (Ghana)" ],
    [ "sq" , "Albanian" ],
    [ "sq-AL" , "Albanian (Albania)" ],
    [ "sq-ALN" , "Albanian (Gheg)" ],
    [ "sq-XK" , "Albanian (Kosovo)" ],
    [ "sq-ME" , "Albanian (Montenegro)" ],
    [ "sq-MK" , "Albanian (North Macedonia)" ],
    [ "sq-ALS" , "Albanian (Tosk)" ],
    [ "gsw" , "Alsatian" ],
    [ "gsw-FR" , "Alsatian (France)" ],
    [ "am" , "Amharic" ],
    [ "am-ET" , "Amharic (Ethiopia)" ],
    [ "am-US" , "Amharic (United States)" ],
    [ "anu" , "Anuak" ],
    [ "apa" , "Apache languages" ],
    [ "ar" , "Arabic" ],
    [ "ar-DZ" , "Arabic (Algeria)" ],
    [ "ar-BH" , "Arabic (Bahrain)" ],
    [ "ar-TD" , "Arabic (Chad)" ],
    [ "ar-EG" , "Arabic (Egypt)" ],
    [ "ar-IQ" , "Arabic (Iraq)" ],
    [ "ar-IL" , "Arabic (Israel)" ],
    [ "ar-JO" , "Arabic (Jordan)" ],
    [ "ar-kab" , "Arabic (Kabyle)" ],
    [ "ar-KW" , "Arabic (Kuwait)" ],
    [ "ar-LB" , "Arabic (Lebanon)" ],
    [ "ar-LY" , "Arabic (Libya)" ],
    [ "ar-MR" , "Arabic (Mauritania)" ],
    [ "ar-MA" , "Arabic (Morocco)" ],
    [ "ar-OM" , "Arabic (Oman)" ],
    [ "ar-AA" , "Arabic (Other)" ],
    [ "ar-PS" , "Arabic (Palestine)" ],
    [ "ar-QA" , "Arabic (Qatar)" ],
    [ "ar-SA" , "Arabic (Saudi Arabia)" ],
    [ "ar-SY" , "Arabic (Syria)" ],
    [ "ar-TN" , "Arabic (Tunisia)" ],
    [ "ar-AE" , "Arabic (U.A.E.)" ],
    [ "ar-YE" , "Arabic (Yemen)" ],
    [ "hy" , "Armenian" ],
    [ "hy-AM" , "Armenian (Armenia)" ],
    [ "hy-east" , "Armenian (East)" ],
    [ "hy-west" , "Armenian (West)" ],
    [ "as" , "Assamese" ],
    [ "as-IN" , "Assamese (India)" ],
    [ "aii" , "Assyrian Neo-Aramaic" ],
    [ "az" , "Azeri" ],
    [ "az-Cyrl" , "Azeri (Cyrillic)" ],
    [ "az-Cyrl-AZ" , "Azeri (Cyrillic, Azerbaijan)" ],
    [ "az-Latn" , "Azeri (Latin)" ],
    [ "az-Latn-AZ" , "Azeri (Latin, Azerbaijan)" ],
    [ "bal" , "Balochi" ],
    [ "bm" , "Bambara" ],
    [ "bxg" , "Bangala" ],
    [ "ba" , "Bashkir" ],
    [ "ba-RU" , "Bashkir (Russia)" ],
    [ "eu" , "Basque" ],
    [ "eu-ES" , "Basque (Basque)" ],
    [ "be" , "Belarusian" ],
    [ "be-BY" , "Belarusian (Belarus)" ],
    [ "bem" , "Bemba" ],
    [ "bn" , "Bengali" ],
    [ "bn-BD" , "Bengali (Bangladesh)" ],
    [ "bn-IN" , "Bengali (India)" ],
    [ "ber" , "Berber" ],
    [ "bho" , "Bhojpuri" ],
    [ "bik" , "Bikol" ],
    [ "xbd" , "Bindal" ],
    [ "bi" , "Bislama" ],
    [ "bla" , "Blackfoot" ],
    [ "bla-Latn" , "Blackfoot (Latin)" ],
    [ "bla-Cans" , "Blackfoot (Syllabics)" ],
    [ "byn" , "Blin" ],
    [ "brx" , "Bodo" ],
    [ "bs" , "Bosnian" ],
    [ "bs-Cyrl" , "Bosnian (Cyrillic)" ],
    [ "bs-Cyrl-BA" , "Bosnian (Cyrillic, Bosnia and Herzegovina)" ],
    [ "bs-Latn" , "Bosnian (Latin)" ],
    [ "bs-Latn-BA" , "Bosnian (Latin, Bosnia and Herzegovina)" ],
    [ "br" , "Breton" ],
    [ "br-FR" , "Breton (France)" ],
    [ "bg" , "Bulgarian" ],
    [ "bg-BG" , "Bulgarian (Bulgaria)" ],
    [ "my" , "Burmese" ],
    [ "my-MM" , "Burmese (Myanmar)" ],
    [ "cbv" , "Cacua" ],
    [ "yue" , "Cantonese" ],
    [ "yue-CN" , "Cantonese (Simplified, China)" ],
    [ "yue-MY" , "Cantonese (Simplified, Malaysia)" ],
    [ "kea" , "Cape Verdean Creole" ],
    [ "hns" , "Caribbean Hindustani" ],
    [ "ca" , "Catalan" ],
    [ "ca-ES" , "Catalan (Spain)" ],
    [ "ceb-PH" , "Cebuano (Philippines)" ],
    [ "ceb-dav-PH" , "Cebuano Davao (Philippines)" ],
    [ "knc" , "Central Kanuri" ],
    [ "cld" , "Chaldean Neo-Aramaic" ],
    [ "ch" , "Chamorro" ],
    [ "cbk" , "Chavacano" ],
    [ "ny-MW" , "Chichewa (Malawi)" ],
    [ "zh" , "Chinese" ],
    [ "zh-Hans" , "Chinese (Simplified)" ],
    [ "zh-CHS" , "Chinese (Simplified) Legacy" ],
    [ "zh-Cn-HK" , "Chinese (Simplified, Hong Kong S.A.R.)" ],
    [ "zh-MY" , "Chinese (Simplified, Malaysia)" ],
    [ "zh-Cn-AU" , "Chinese (Simplified, Australia)" ],
    [ "zh-CN" , "Chinese (Simplified, PRC)" ],
    [ "zh-SG" , "Chinese (Simplified, Singapore)" ],
    [ "zh-Hant" , "Chinese (Traditional)" ],
    [ "zh-CHT" , "Chinese (Traditional) Legacy" ],
    [ "zh-AU" , "Chinese (Traditional, Australia)" ],
    [ "zh-HK" , "Chinese (Traditional, Hong Kong S.A.R.)" ],
    [ "zh-MO" , "Chinese (Traditional, Macao S.A.R.)" ],
    [ "zh-TW" , "Chinese (Traditional, Taiwan)" ],
    [ "zh-US" , "Chinese (Traditional, United States)" ],
    [ "chp" , "Chipewyan" ],
    [ "chp-Latn" , "Chipewyan (Latin)" ],
    [ "chp-Cans" , "Chipewyan (Syllabics)" ],
    [ "chk" , "Chuukese" ],
    [ "cv" , "Chuvash" ],
    [ "co" , "Corsican" ],
    [ "co-FR" , "Corsican (France)" ],
    [ "cre" , "Cree" ],
    [ "cre-Latn" , "Cree (Latin)" ],
    [ "cre-Cans" , "Cree (Syllabics)" ],
    [ "cpe" , "Creole (English)" ],
    [ "cpf" , "Creole (French)" ],
    [ "cpp" , "Creole (Portuguese)" ],
    [ "hr" , "Croatian" ],
    [ "hr-HR" , "Croatian (Croatia)" ],
    [ "hr-BA" , "Croatian (Latin, Bosnia and Herzegovina)" ],
    [ "cs" , "Czech" ],
    [ "cs-CZ" , "Czech (Czech Republic)" ],
    [ "cs-SK" , "Czech (Slovak Republic)" ],
    [ "dag-GH" , "Dagbani (Ghana)" ],
    [ "da" , "Danish" ],
    [ "da-DK" , "Danish (Denmark)" ],
    [ "da-GL" , "Danish (Greenland)" ],
    [ "prs" , "Dari" ],
    [ "prs-AF" , "Dari (Afghanistan)" ],
    [ "luo" , "Dholuo" ],
    [ "did" , "Didinga" ],
    [ "din" , "Dinka" ],
    [ "dv" , "Divehi" ],
    [ "dv-MV" , "Divehi (Maldives)" ],
    [ "doi" , "Dogri" ],
    [ "nl" , "Dutch" ],
    [ "nl-BE" , "Dutch (Belgium)" ],
    [ "nl-CW" , "Dutch (Curacao)" ],
    [ "nl-NL" , "Dutch (Netherlands)" ],
    [ "dz" , "Dzongkha" ],
    [ "dz-BT" , "Dzongkha (Bhutan)" ],
    [ "cjm" , "Eastern Cham" ],
    [ "bin" , "Edo" ],
    [ "efi" , "Efik" ],
    [ "en" , "English" ],
    [ "en-AR" , "English (Argentina)" ],
    [ "en-AU" , "English (Australia)" ],
    [ "en-BH" , "English (Bahrain)" ],
    [ "en-BD" , "English (Bangladesh)" ],
    [ "en-BE" , "English (Belgium)" ],
    [ "en-BZ" , "English (Belize)" ],
    [ "en-CM" , "English (Cameroon)" ],
    [ "en-CA" , "English (Canada)" ],
    [ "en-029" , "English (Caribbean)" ],
    [ "en-CY" , "English (Cyprus)" ],
    [ "en-EG" , "English (Egypt)" ],
    [ "en-GM" , "English (Gambia)" ],
    [ "en-GE" , "English (Georgia)" ],
    [ "en-GH" , "English (Ghana)" ],
    [ "en-HK" , "English (Hong Kong)" ],
    [ "en-IS" , "English (Iceland)" ],
    [ "en-IN" , "English (India)" ],
    [ "en-ID" , "English (Indonesia)" ],
    [ "en-IE" , "English (Ireland)" ],
    [ "en-IL" , "English (Israel)" ],
    [ "en-JM" , "English (Jamaica)" ],
    [ "en-JP" , "English (Japan)" ],
    [ "en-JO" , "English (Jordan)" ],
    [ "en-KE" , "English (Kenya)" ],
    [ "en-KW" , "English (Kuwait)" ],
    [ "en-LB" , "English (Lebanon)" ],
    [ "en-LR" , "English (Liberia)" ],
    [ "en-LT" , "English (Lithuania)" ],
    [ "en-MY" , "English (Malaysia)" ],
    [ "en-MT" , "English (Malta)" ],
    [ "en-MEA" , "English (Middle East and Africa)" ],
    [ "en-NZ" , "English (New Zealand)" ],
    [ "en-NG" , "English (Nigeria)" ],
    [ "en-OM" , "English (Oman)" ],
    [ "en-CN" , "English (PRC)" ],
    [ "en-QA" , "English (Qatar)" ],
    [ "en-PH" , "English (Republic of the Philippines)" ],
    [ "en-RW" , "English (Rwanda)" ],
    [ "en-SA" , "English (Saudi Arabia)" ],
    [ "en-SG" , "English (Singapore)" ],
    [ "en-ZA" , "English (South Africa)" ],
    [ "en-LK" , "English (Sri Lanka)" ],
    [ "en-CH" , "English (Switzerland)" ],
    [ "en-TZ" , "English (Tanzania)" ],
    [ "en-TH" , "English (Thailand)" ],
    [ "en-TG" , "English (Togo)" ],
    [ "en-TT" , "English (Trinidad and Tobago)" ],
    [ "en-TV" , "English (Tuvalu)" ],
    [ "en-AE" , "English (U.A.E.)" ],
    [ "en-UG" , "English (Uganda)" ],
    [ "en-GB" , "English (United Kingdom)" ],
    [ "en-US" , "English (United States)" ],
    [ "en-ZW" , "English (Zimbabwe)" ],
    [ "eo" , "Esperanto" ],
    [ "et" , "Estonian" ],
    [ "et-EE" , "Estonian (Estonia)" ],
    [ "ee" , "Ewe" ],
    [ "ee-GH" , "Ewe (Ghana)" ],
    [ "cfm" , "Falam Chin" ],
    [ "fat" , "Fanti" ],
    [ "gur" , "Farefare" ],
    [ "fo" , "Faroese" ],
    [ "fo-FO" , "Faroese (Faroe Islands)" ],
    [ "fj" , "Fijian" ],
    [ "fil" , "Filipino" ],
    [ "fil-PH" , "Filipino (Philippines)" ],
    [ "fi" , "Finnish" ],
    [ "fi-FI" , "Finnish (Finland)" ],
    [ "fr" , "French" ],
    [ "fr-DZ" , "French (Algeria)" ],
    [ "fr-BE" , "French (Belgium)" ],
    [ "fr-BJ" , "French (Benin)" ],
    [ "fr-BF" , "French (Burkina Faso)" ],
    [ "fr-CM" , "French (Cameroon)" ],
    [ "fr-CA" , "French (Canada)" ],
    [ "fr-CF" , "French (Central African Republic)" ],
    [ "fr-TD" , "French (Chad)" ],
    [ "fr-CG" , "French (Congo)" ],
    [ "fr-CI" , "French (Cote d'Ivoire)" ],
    [ "fr-GQ" , "French (Equatorial Guinea)" ],
    [ "fr-FR" , "French (France)" ],
    [ "fr-GA" , "French (Gabon)" ],
    [ "fr-GN" , "French (Guinea)" ],
    [ "fr-GW" , "French (Guinea-Bissau)" ],
    [ "fr-HT" , "French (HaÃ¯ti)" ],
    [ "fr-LB" , "French (Lebanon)" ],
    [ "fr-LU" , "French (Luxembourg)" ],
    [ "fr-ML" , "French (Mali)" ],
    [ "fr-MQ" , "French (Martinique)" ],
    [ "fr-MR" , "French (Mauritania)" ],
    [ "fr-MEA" , "French (Middle East and Africa)" ],
    [ "fr-MC" , "French (Monaco)" ],
    [ "fr-MA" , "French (Morocco)" ],
    [ "fr-NC" , "French (New Caledonia)" ],
    [ "fr-NE" , "French (Niger)" ],
    [ "fr-RE" , "French (Reunion)" ],
    [ "fr-RW" , "French (Rwanda)" ],
    [ "fr-SN" , "French (Senegal)" ],
    [ "fr-SL" , "French (Sierra Leone)" ],
    [ "fr-CH" , "French (Switzerland)" ],
    [ "fr-TG" , "French (Togo)" ],
    [ "fr-TN" , "French (Tunisia)" ],
    [ "fr-PF" , "French Polynesia" ],
    [ "fy" , "Frisian" ],
    [ "fy-NL" , "Frisian (Netherlands)" ],
    [ "ff" , "Fulani" ],
    [ "gaa-GH" , "Ga (Ghana)" ],
    [ "gl" , "Galician" ],
    [ "gl-ES" , "Galician (Galician)" ],
    [ "cab" , "Garifuna" ],
    [ "ka" , "Georgian" ],
    [ "ka-GE" , "Georgian (Georgia)" ],
    [ "de" , "German" ],
    [ "de-AT" , "German (Austria)" ],
    [ "de-BE" , "German (Belgium)" ],
    [ "de-DE" , "German (Germany)" ],
    [ "de-IT" , "German (Italy)" ],
    [ "de-LI" , "German (Liechtenstein)" ],
    [ "de-LU" , "German (Luxembourg)" ],
    [ "de-CH" , "German (Switzerland)" ],
    [ "gil" , "Gilbertese" ],
    [ "el" , "Greek" ],
    [ "el-CY" , "Greek (Cyprus)" ],
    [ "el-GR" , "Greek (Greece)" ],
    [ "kl" , "Greenlandic" ],
    [ "kl-GL" , "Greenlandic (Greenland)" ],
    [ "gu" , "Gujarati" ],
    [ "gu-IN" , "Gujarati (India)" ],
    [ "gu-GB" , "Gujarati (United Kingdom)" ],
    [ "gwr" , "Gwere" ],
    [ "ht" , "Haitian (Creole)" ],
    [ "cnh" , "Hakha Chin" ],
    [ "ha" , "Hausa" ],
    [ "ha-Arab" , "Hausa (Arabic)" ],
    [ "ha-Latn" , "Hausa (Latin)" ],
    [ "ha-Latn-NG" , "Hausa (Latin, Nigeria)" ],
    [ "haw" , "Hawaiian" ],
    [ "haw-HI" , "Hawaiian (Hawaii)" ],
    [ "haw-US" , "Hawaiian (United States)" ],
    [ "haz" , "Hazaragi" ],
    [ "he" , "Hebrew" ],
    [ "he-IL" , "Hebrew (Israel)" ],
    [ "hil-PH" , "Hiligaynon (Philippines)" ],
    [ "hi" , "Hindi" ],
    [ "hi-CA" , "Hindi (Canada)" ],
    [ "hi-IN" , "Hindi (India)" ],
    [ "hmn" , "Hmong" ],
    [ "blu" , "Hmong (Deprecated)" ],
    [ "hnj" , "Hmong (Njua)" ],
    [ "nan" , "Hokkien" ],
    [ "hu" , "Hungarian" ],
    [ "hu-HU" , "Hungarian (Hungary)" ],
    [ "hu-SK" , "Hungarian (Slovakia)" ],
    [ "is" , "Icelandic" ],
    [ "is-IS" , "Icelandic (Iceland)" ],
    [ "ig" , "Igbo" ],
    [ "ig-NG" , "Igbo (Nigeria)" ],
    [ "ilo-PH" , "Ilokano (Philippines)" ],
    [ "id" , "Indonesian" ],
    [ "id-ID" , "Indonesian (Indonesia)" ],
    [ "iku" , "Inuktitut" ],
    [ "iku-Latn" , "Inuktitut (Latin)" ],
    [ "iku-Latn-CA" , "Inuktitut (Latin, Canada)" ],
    [ "iku-Cans" , "Inuktitut (Syllabics)" ],
    [ "iku-Cans-CA" , "Inuktitut (Syllabics, Canada)" ],
    [ "ga" , "Irish" ],
    [ "ga-IE" , "Irish (Ireland)" ],
    [ "xh" , "isiXhosa" ],
    [ "xh-ZA" , "isiXhosa (South Africa)" ],
    [ "it" , "Italian" ],
    [ "it-IT" , "Italian (Italy)" ],
    [ "it-MT" , "Italian (Malta)" ],
    [ "it-CH" , "Italian (Switzerland)" ],
    [ "ium" , "Iu Mien" ],
    [ "ja" , "Japanese" ],
    [ "ja-JP" , "Japanese (Japan)" ],
    [ "ja-latn" , "Japanese (Latin)" ],
    [ "ja-US" , "Japanese (United States)" ],
    [ "jv" , "Javanese" ],
    [ "kac" , "Kachin" ],
    [ "kjb" , "Kanjobal" ],
    [ "kn" , "Kannada" ],
    [ "kn-IN" , "Kannada (India)" ],
    [ "kr" , "Kanuri" ],
    [ "pam" , "Kapampangan" ],
    [ "kar" , "Karen" ],
    [ "eky" , "Karenni" ],
    [ "xsm" , "Kasem" ],
    [ "ks" , "Kashmiri" ],
    [ "kk" , "Kazakh" ],
    [ "kk-KZ" , "Kazakh (Kazakhstan)" ],
    [ "km" , "Khmer" ],
    [ "km-KH" , "Khmer (Cambodia)" ],
    [ "qut" , "Kiche" ],
    [ "qut-GT" , "Kiche (Guatemala)" ],
    [ "kg" , "Kikongo" ],
    [ "ki" , "Kikuyu" ],
    [ "ki-KE" , "Kikuyu (Kenya)" ],
    [ "rw" , "Kinyarwanda" ],
    [ "rw-RW" , "Kinyarwanda (Rwanda)" ],
    [ "rn" , "Kirundi" ],
    [ "kok" , "Konkani" ],
    [ "kok-IN" , "Konkani (India)" ],
    [ "ko" , "Korean" ],
    [ "ko-KR" , "Korean (Korea)" ],
    [ "ko-US" , "Korean (United States)" ],
    [ "kri" , "Krio" ],
    [ "kun" , "Kunama" ],
    [ "ku" , "Kurdish" ],
    [ "ku-IQ" , "Kurdish (Iraq)" ],
    [ "kmr" , "Kurmanji" ],
    [ "ky" , "Kyrgyz" ],
    [ "ky-KG" , "Kyrgyz (Kyrgyzstan)" ],
    [ "lah" , "Lahnda" ],
    [ "lah-PK" , "Lahnda (Pakistan Punjabi)" ],
    [ "lah-PNB" , "Lahnda (Western Panjabi)" ],
    [ "lo" , "Lao" ],
    [ "lo-LA" , "Lao (Lao P.D.R.)" ],
    [ "la" , "Latin" ],
    [ "lv" , "Latvian" ],
    [ "lv-LV" , "Latvian (Latvia)" ],
    [ "ln" , "Lingala" ],
    [ "lt" , "Lithuanian" ],
    [ "lt-LT" , "Lithuanian (Lithuania)" ],
    [ "dsb" , "Lower Sorbian" ],
    [ "dsb-DE" , "Lower Sorbian (Germany)" ],
    [ "loz-ZM" , "Lozi (Zambia)" ],
    [ "lug" , "Luganda" ],
    [ "lug-UG" , "Luganda (Uganda)" ],
    [ "luo-KE" , "Luo (Kenya)" ],
    [ "lb" , "Luxembourgish" ],
    [ "lb-LU" , "Luxembourgish (Luxembourg)" ],
    [ "mas" , "Maasai" ],
    [ "ymm" , "Maay Maay" ],
    [ "mk" , "Macedonian (FYROM)" ],
    [ "mk-MK" , "Macedonian (North Macedonia)" ],
    [ "mdh" , "Maguindanaon" ],
    [ "mai" , "Maithili" ],
    [ "mg" , "Malagasy" ],
    [ "ms" , "Malay" ],
    [ "ms-BN" , "Malay (Brunei Darussalam)" ],
    [ "ms-MY" , "Malay (Malaysia)" ],
    [ "ms-SG" , "Malay (Singapore)" ],
    [ "ml" , "Malayalam" ],
    [ "ml-IN" , "Malayalam (India)" ],
    [ "mt" , "Maltese" ],
    [ "mt-MT" , "Maltese (Malta)" ],
    [ "mam" , "Mam" ],
    [ "cmn-US" , "Mandarin Chinese (Simplified, United States)" ],
    [ "cmn-HK" , "Mandarin Chinese (Traditional, Hong Kong S.A.R.)" ],
    [ "mnk" , "Mandinka" ],
    [ "mi" , "Maori" ],
    [ "mi-NZ" , "Maori (New Zealand)" ],
    [ "arn" , "Mapudungun" ],
    [ "arn-CL" , "Mapudungun (Chile)" ],
    [ "mr" , "Marathi" ],
    [ "mr-IN" , "Marathi (India)" ],
    [ "mh" , "Marshallese" ],
    [ "mwr" , "Marwari" ],
    [ "myx" , "Masaaba" ],
    [ "mni" , "Meitei" ],
    [ "men" , "Mende" ],
    [ "mih" , "Mixtec Chayuco" ],
    [ "mxt" , "Mixtec Jamiltepec" ],
    [ "mix" , "Mixtec Mixtepec" ],
    [ "xtj" , "Mixtec San Juan Teita" ],
    [ "moh" , "Mohawk" ],
    [ "moh-CA" , "Mohawk (Mohawk)" ],
    [ "mn" , "Mongolian" ],
    [ "mn-Cyrl" , "Mongolian (Cyrillic)" ],
    [ "mn-MN" , "Mongolian (Cyrillic, Mongolia)" ],
    [ "mn-Mong" , "Mongolian (Traditional Mongolian)" ],
    [ "mn-Mong-CN" , "Mongolian (Traditional Mongolian, PRC)" ],
    [ "cnr" , "Montenegrin" ],
    [ "cnr-CNR" , "Montenegrin (Montenegro)" ],
    [ "mos" , "MoorÃ©" ],
    [ "mos-BF" , "MoorÃ© (Burkina Faso)" ],
    [ "kin-MUL" , "Mulenge (Congo)" ],
    [ "nv" , "Navajo" ],
    [ "nd" , "Ndebele (North)" ],
    [ "nr" , "Ndebele (South)" ],
    [ "ne" , "Nepali" ],
    [ "ne-NP" , "Nepali (Nepal)" ],
    [ "no" , "Norwegian" ],
    [ "nb" , "Norwegian (BokmÃ¥l)" ],
    [ "no-DK" , "Norwegian (Denmark)" ],
    [ "no-NO" , "Norwegian (Norway)" ],
    [ "nn" , "Norwegian (Nynorsk)" ],
    [ "nb-NO" , "Norwegian, BokmÃ¥l (Norway)" ],
    [ "nn-NO" , "Norwegian, Nynorsk (Norway)" ],
    [ "nus" , "Nuer" ],
    [ "nyy" , "Nyakyusa" ],
    [ "ny" , "Nyanja" ],
    [ "oc" , "Occitan" ],
    [ "oc-FR" , "Occitan (France)" ],
    [ "ryu" , "Okinawan" ],
    [ "or" , "Oriya" ],
    [ "or-IN" , "Oriya (India)" ],
    [ "om" , "Oromo" ],
    [ "om-ET" , "Oromo (Ethiopia)" ],
    [ "kua" , "Oshiwambo" ],
    [ "pau" , "Palauan" ],
    [ "pag" , "Pangasinan" ],
    [ "pap" , "Papiamento" ],
    [ "pap-CW" , "Papiamento (Curacao)" ],
    [ "pap-pu" , "Papiamentu" ],
    [ "ps" , "Pashto" ],
    [ "ps-AF" , "Pashto (Afghanistan)" ],
    [ "ps-PK" , "Pashto (Pakistan)" ],
    [ "pdc" , "Pennsylvania German" ],
    [ "fa" , "Persian" ],
    [ "fa-IR" , "Persian (Iran)" ],
    [ "peo" , "Persian (Old)" ],
    [ "pcm-NG" , "Pidgin (Nigeria)" ],
    [ "crk" , "Plains Cree" ],
    [ "crk-Latn" , "Plains Cree (Latin)" ],
    [ "crk-Cans" , "Plains Cree (Syllabics)" ],
    [ "pon" , "Pohnpeian" ],
    [ "pl" , "Polish" ],
    [ "pl-PL" , "Polish (Poland)" ],
    [ "pt" , "Portuguese" ],
    [ "pt-AO" , "Portuguese (Angola)" ],
    [ "pt-BR" , "Portuguese (Brazil)" ],
    [ "pt-GQ" , "Portuguese (Equatorial Guinea)" ],
    [ "pt-GW" , "Portuguese (Guinea-Bissau)" ],
    [ "pt-MZ" , "Portuguese (Mozambique)" ],
    [ "pt-PT" , "Portuguese (Portugal)" ],
    [ "pa" , "Punjabi" ],
    [ "pa-CA" , "Punjabi (Canada)" ],
    [ "pa-IN" , "Punjabi (India)" ],
    [ "pa-PK" , "Punjabi (Pakistan)" ],
    [ "quz" , "Quechua" ],
    [ "quz-BO" , "Quechua (Bolivia)" ],
    [ "quz-EC" , "Quechua (Ecuador)" ],
    [ "quz-PE" , "Quechua (Peru)" ],
    [ "rhg" , "Rohingya" ],
    [ "rom" , "Romani" ],
    [ "ro" , "Romanian" ],
    [ "ro-MD" , "Romanian (Moldova)" ],
    [ "ro-RO" , "Romanian (Romania)" ],
    [ "rm" , "Romansh" ],
    [ "rm-CH" , "Romansh (Switzerland)" ],
    [ "nyn" , "Runyankore (NKore)" ],
    [ "ru" , "Russian" ],
    [ "ru-BY" , "Russian (Belarus)" ],
    [ "ru-EE" , "Russian (Estonia)" ],
    [ "ru-GE" , "Russian (Georgia)" ],
    [ "ru-IL" , "Russian (Israel)" ],
    [ "ru-KZ" , "Russian (Kazakhstan)" ],
    [ "ru-KG" , "Russian (Kyrgyzstan)" ],
    [ "ru-LV" , "Russian (Latvia)" ],
    [ "ru-LT" , "Russian (Lithuania)" ],
    [ "ru-MD" , "Russian (Moldova)" ],
    [ "ru-RU" , "Russian (Russia)" ],
    [ "ru-UA" , "Russian (Ukraine)" ],
    [ "sah" , "Sakha" ],
    [ "sah-RU" , "Sakha (Russia)" ],
    [ "smn" , "Sami (Inari)" ],
    [ "smj" , "Sami (Lule)" ],
    [ "se" , "Sami (Northern)" ],
    [ "sms" , "Sami (Skolt)" ],
    [ "sma" , "Sami (Southern)" ],
    [ "smn-FI" , "Sami, Inari (Finland)" ],
    [ "smj-NO" , "Sami, Lule (Norway)" ],
    [ "smj-SE" , "Sami, Lule (Sweden)" ],
    [ "se-FI" , "Sami, Northern (Finland)" ],
    [ "se-NO" , "Sami, Northern (Norway)" ],
    [ "se-SE" , "Sami, Northern (Sweden)" ],
    [ "sms-FI" , "Sami, Skolt (Finland)" ],
    [ "sma-NO" , "Sami, Southern (Norway)" ],
    [ "sma-SE" , "Sami, Southern (Sweden)" ],
    [ "sm" , "Samoan" ],
    [ "sg" , "Sango" ],
    [ "sa" , "Sanskrit" ],
    [ "sa-IN" , "Sanskrit (India)" ],
    [ "sat" , "Santali" ],
    [ "skr" , "Saraiki" ],
    [ "gd" , "Scottish Gaelic" ],
    [ "gd-GB" , "Scottish Gaelic (United Kingdom)" ],
    [ "sr" , "Serbian" ],
    [ "sr-Cyrl" , "Serbian (Cyrillic)" ],
    [ "sr-Cyrl-BA" , "Serbian (Cyrillic, Bosnia and Herzegovina)" ],
    [ "sr-Cyrl-ME" , "Serbian (Cyrillic, Montenegro)" ],
    [ "sr-Cyrl-CS" , "Serbian (Cyrillic, Serbia and Montenegro (Former))" ],
    [ "sr-Cyrl-RS" , "Serbian (Cyrillic, Serbia)" ],
    [ "sr-XK" , "Serbian (Kosovo)" ],
    [ "sr-Latn" , "Serbian (Latin)" ],
    [ "sr-Latn-BA" , "Serbian (Latin, Bosnia and Herzegovina)" ],
    [ "sr-Latn-ME" , "Serbian (Latin, Montenegro)" ],
    [ "sr-Latn-CS" , "Serbian (Latin, Serbia and Montenegro (Former))" ],
    [ "sr-Latn-RS" , "Serbian (Latin, Serbia)" ],
    [ "sr-RS" , "Serbian (Serbia)" ],
    [ "sh" , "Serbo-Croatian" ],
    [ "st" , "Sesotho" ],
    [ "st-ZA" , "Sesotho (South Africa)" ],
    [ "nso" , "Sesotho sa Leboa" ],
    [ "nso-ZA" , "Sesotho sa Leboa (South Africa)" ],
    [ "tn" , "Setswana" ],
    [ "tn-ZA" , "Setswana (South Africa)" ],
    [ "ksw-MM" , "S'gaw Karen" ],
    [ "shn" , "Shan" ],
    [ "suji" , "Shanghainese" ],
    [ "sn" , "Shona" ],
    [ "sd" , "Sindhi" ],
    [ "si" , "Sinhala" ],
    [ "si-LK" , "Sinhala (Sri Lanka)" ],
    [ "ss" , "Siswati" ],
    [ "sk" , "Slovak" ],
    [ "sk-SK" , "Slovak (Slovakia)" ],
    [ "sl" , "Slovenian" ],
    [ "sl-SI" , "Slovenian (Slovenia)" ],
    [ "xog" , "Soga" ],
    [ "so" , "Somali" ],
    [ "snk" , "Soninke" ],
    [ "ckb" , "Sorani" ],
    [ "es" , "Spanish" ],
    [ "es-AR" , "Spanish (Argentina)" ],
    [ "es-VE" , "Spanish (Bolivarian Republic of Venezuela)" ],
    [ "es-BO" , "Spanish (Bolivia)" ],
    [ "es-CL" , "Spanish (Chile)" ],
    [ "es-CO" , "Spanish (Colombia)" ],
    [ "es-CR" , "Spanish (Costa Rica)" ],
    [ "es-CU" , "Spanish (Cuba)" ],
    [ "es-DO" , "Spanish (Dominican Republic)" ],
    [ "es-EC" , "Spanish (Ecuador)" ],
    [ "es-SV" , "Spanish (El Salvador)" ],
    [ "es-GQ" , "Spanish (Equatorial Guinea)" ],
    [ "es-GT" , "Spanish (Guatemala)" ],
    [ "es-HN" , "Spanish (Honduras)" ],
    [ "es-001" , "Spanish (International)" ],
    [ "es-419" , "Spanish (Latin America)" ],
    [ "es-MX" , "Spanish (Mexico)" ],
    [ "es-NI" , "Spanish (Nicaragua)" ],
    [ "es-PA" , "Spanish (Panama)" ],
    [ "es-PY" , "Spanish (Paraguay)" ],
    [ "es-PE" , "Spanish (Peru)" ],
    [ "es-PR" , "Spanish (Puerto Rico)" ],
    [ "es-ES" , "Spanish (Spain)" ],
    [ "es-US" , "Spanish (United States)" ],
    [ "es-UY" , "Spanish (Uruguay)" ],
    [ "srn" , "Sranan" ],
    [ "suk" , "Sukuma" ],
    [ "su" , "Sundanese" ],
    [ "sw" , "Swahili" ],
    [ "sw-KE" , "Swahili (Kenya)" ],
    [ "sw-TZ" , "Swahili (Tanzania)" ],
    [ "sw-UG" , "Swahili (Uganda)" ],
    [ "sv" , "Swedish" ],
    [ "sv-AX" , "Swedish (Aland)" ],
    [ "sv-DK" , "Swedish (Denmark)" ],
    [ "sv-FI" , "Swedish (Finland)" ],
    [ "sv-SE" , "Swedish (Sweden)" ],
    [ "syl" , "Sylheti" ],
    [ "syr" , "Syriac" ],
    [ "syr-SY" , "Syriac (Syria)" ],
    [ "tl" , "Tagalog" ],
    [ "tl-PH" , "Tagalog (Philippines)" ],
    [ "ty" , "Tahitian" ],
    [ "nan-TW" , "Taiwanese (Taiwan)" ],
    [ "tg" , "Tajik" ],
    [ "tg-Cyrl" , "Tajik (Cyrillic)" ],
    [ "tg-Cyrl-TJ" , "Tajik (Cyrillic, Tajikistan)" ],
    [ "tg-latn" , "Tajik (Latin)" ],
    [ "tzm" , "Tamazight" ],
    [ "tzm-Latn" , "Tamazight (Latin)" ],
    [ "tzm-Latn-DZ" , "Tamazight (Latin, Algeria)" ],
    [ "ta" , "Tamil" ],
    [ "ta-IN" , "Tamil (India)" ],
    [ "ta-MY" , "Tamil (Malaysia)" ],
    [ "ta-SG" , "Tamil (Singapore)" ],
    [ "ta-LK" , "Tamil (Sri Lanka)" ],
    [ "tt" , "Tatar" ],
    [ "tt-RU" , "Tatar (Russia)" ],
    [ "ctd" , "Tedim Chin" ],
    [ "te" , "Telugu" ],
    [ "te-IN" , "Telugu (India)" ],
    [ "tem" , "Temne" ],
    [ "teo-UG" , "Teso (Uganda)" ],
    [ "tdt" , "Tetun" ],
    [ "th" , "Thai" ],
    [ "th-TH" , "Thai (Thailand)" ],
    [ "bo" , "Tibetan" ],
    [ "bo-CN" , "Tibetan (PRC)" ],
    [ "ti" , "Tigrinya" ],
    [ "tpi" , "Tok Pisin" ],
    [ "toi" , "Tonga (Zambia)" ],
    [ "to" , "Tongan" ],
    [ "to-ZM" , "Tongan (Zambia)" ],
    [ "toq" , "Toposa" ],
    [ "lua" , "Tshiluba" ],
    [ "ts" , "Tsonga" ],
    [ "tum" , "Tumbuka" ],
    [ "tr" , "Turkish" ],
    [ "tr-CY" , "Turkish (Cyprus)" ],
    [ "tr-DE" , "Turkish (Germany)" ],
    [ "tr-TR" , "Turkish (Turkey)" ],
    [ "tk" , "Turkmen" ],
    [ "tk-TM" , "Turkmen (Turkmenistan)" ],
    [ "twi" , "Twi" ],
    [ "tw-GH" , "Twi (Ghana)" ],
    [ "uk" , "Ukrainian" ],
    [ "uk-UA" , "Ukrainian (Ukraine)" ],
    [ "xx" , "Unknown" ],
    [ "hsb" , "Upper Sorbian" ],
    [ "hsb-DE" , "Upper Sorbian (Germany)" ],
    [ "ur" , "Urdu" ],
    [ "ur-IN" , "Urdu (India)" ],
    [ "ur-PK" , "Urdu (Islamic Republic of Pakistan)" ],
    [ "ug" , "Uyghur" ],
    [ "ug-CN" , "Uyghur (PRC)" ],
    [ "uz" , "Uzbek" ],
    [ "uz-Cyrl" , "Uzbek (Cyrillic)" ],
    [ "uz-Cyrl-UZ" , "Uzbek (Cyrillic, Uzbekistan)" ],
    [ "uz-Latn" , "Uzbek (Latin)" ],
    [ "uz-Latn-UZ" , "Uzbek (Latin, Uzbekistan)" ],
    [ "ca-val" , "Valencian" ],
    [ "ve" , "Venda" ],
    [ "vi" , "Vietnamese" ],
    [ "vi-CA" , "Vietnamese (Canada)" ],
    [ "vi-US" , "Vietnamese (United States)" ],
    [ "vi-VN" , "Vietnamese (Vietnam)" ],
    [ "war" , "Waray" ],
    [ "cy" , "Welsh" ],
    [ "cy-GB" , "Welsh (United Kingdom)" ],
    [ "cja" , "Western Cham" ],
    [ "wo" , "Wolof" ],
    [ "wo-SN" , "Wolof (Senegal)" ],
    [ "yao" , "Yao" ],
    [ "ii" , "Yi" ],
    [ "ii-CN" , "Yi (PRC)" ],
    [ "yi" , "Yiddish" ],
    [ "yi-US" , "Yiddish (United States)" ],
    [ "yo" , "Yoruba" ],
    [ "yo-NG" , "Yoruba (Nigeria)" ],
    [ "ypk" , "Yupik" ],
    [ "zap" , "Zapotec" ],
    [ "ziw" , "Zigula" ],
    [ "zo" , "Zou" ],
    [ "zu" , "Zulu" ],
    [ "zu-Z" , "Zulu (South Africa)" ],
]
export default languages
