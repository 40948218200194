import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Spinner = () => (
    <div className="text-center">
        <FontAwesomeIcon icon={faSpinner} className="fa-pulse fa-fw" />
    </div>
)

export default Spinner
