var nav = {
    top: [
        {
            name: "Facilities",
            url: "/admin/facilities",
            icon: "Package",
        },
        {
            name: "Roles",
            url: "/admin/roles",
            icon: "Star",
        },
        {
            name: "Auditlogs",
            url: "/admin/auditlogs",
            icon: "Book",
        },
    ],
};
export default nav;
