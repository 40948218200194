import axios from "axios";
import { encryptionHelper } from "../helpers";

let instance = axios.create({
    // `baseURL` will be prepended to `url` unless `url` is absolute.
    // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
    // to methods of that instance.
    baseURL: process.env.REACT_APP_API_BASE_URL,

    // `method` is the request method to be used when making the request
    method: "get", // default

    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 30000, // default is `0` (no timeout)

    // `withCredentials` indicates whether or not cross-site Access-Control requests
    // should be made using credentials
    withCredentials: false, // default

    // `maxRedirects` defines the maximum number of redirects to follow in node.js.
    // If set to 0, no redirects will be followed.
    maxRedirects: 5, // default

    // `maxContentLength` defines the max size of the http response content in bytes allowed. here 50MBs
    maxContentLength: 50 * 1000 * 1000,

    // `responseType` indicates the type of data that the server will respond with
    // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
    //   browser only: 'blob'
    responseType: "json", // default

    // `responseEncoding` indicates encoding to use for decoding responses
    // Note: Ignored for `responseType` of 'stream' or client-side requests
    responseEncoding: "utf8", // default
});

// optionally add interceptors here...
// Add a request interceptor
instance.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor to check jwt expiry
instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        const { status, data } = error.response;
        if (status === 401 && data.message === "jwt expired") {
            window.location.href = `/admin/login?error=${encryptionHelper.encrypt(
                "Session time out! Please login again."
            )}`;
        }
        return Promise.reject(error);
    }
);

export default instance;
