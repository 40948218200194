/* eslint-disable */
import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// Helper
import AuthGuard from "./components/authGuard";

//Routes
import Dashboard from "./views/pages/admin/Layouts/adminLayout";
import Login from "./views/pages/admin/Login";
import { TranslationRoot } from "./views/pages/translation";

//styles
import "./styles/scss/styles.scss";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    exact
                    path="*"
                    element={<Navigate to="/admin/login" replace />}
                />
                <Route path="/admin/login" element={<Login />} />
                <Route path="/translation/*" element={<TranslationRoot />} />
                <Route element={<AuthGuard />} >
                    <Route path="/admin/*" element={<Dashboard />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
