import React, { createContext, useCallback, useState } from "react"

const TranslationContext = createContext({ })

let initialContext = {}
try {
    initialContext = JSON.parse(window.localStorage.getItem('translation')) || {}
}
catch (ex) {}

export const TranslationContextProvider = ({ children }) => {
    const [ context, setContext ] = useState(initialContext)

    const updateContext = useCallback(
        (value) => {
            const nextContext = { ...context, ...value }
            window.localStorage.setItem('translation', JSON.stringify(nextContext))
            setContext(nextContext)
        },
        [ context, setContext ]
    )

    return (
        <TranslationContext.Provider value={{ ...context, updateContext }}>
            { children }
        </TranslationContext.Provider>
    )
}

export default TranslationContext
