import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { TranslationContextProvider } from "./TranslationContext"
import JobEditPage from './JobEditPage'
import LaunchPage from './LaunchPage'
import StatusPage from './StatusPage'
import TranslationHeader from "./TranslationHeader"

const TranslationRoot = ({ }) => (
    <TranslationContextProvider>
        <DndProvider backend={HTML5Backend}>
            <div className="TranslationRoot">
                <TranslationHeader />
                <div className="body">
                    <Routes>
                        <Route
                            path="/launch/:token/:target"
                            element={<LaunchPage />}
                        />
                        <Route
                            path="/edit"
                            element={<JobEditPage />}
                        />
                        <Route
                            path="/status"
                            element={<StatusPage />}
                        />
                        <Route
                            exact
                            path="*"
                            element={<Navigate to="/admin/login" replace />}
                        />
                    </Routes>
                </div>
            </div>
        </DndProvider>
    </TranslationContextProvider>
)

export default TranslationRoot
