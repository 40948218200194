import React from "react"
import classNames from 'classnames'
import { faFileLines, faClockRotateLeft, faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import JobSummary from "./JobSummary"
import Spinner from "./Spinner"

import { useJobData, useJobStatus } from "./server"

const Step = ({ label, icon, stage, active }) => (
    <div className="step" >
        <div className="label">{ label }</div>
        <div className={classNames({'step-icon': true, [`stage${stage}`]: true, active })} >
            <FontAwesomeIcon icon={icon} size="lg" />
        </div>
    </div>
)

const StepSeparator = ({ active }) => <div className={classNames({ line: true, active })} />

const StatusSteps = ({ status }) => {
    const stage = { New: 1, InProgress: 2, Completed: 3 }[status] || 0
    return (
        <div className="status-steps my-4">
            <Step label="New" icon={faFileLines} stage={1} active={ stage >= 1 } />
            <StepSeparator active={ stage >= 2 } />
            <Step label="In-Progress" icon={faClockRotateLeft} stage={2} active={ stage >= 2 } />
            <StepSeparator active={ stage >= 3 } />
            <Step label="Completed" icon={faCheck} stage={3} active={ stage >= 3 } />
        </div>
    )
}

const StatusPage = ({ }) => {
    const jobData = useJobData()
    const jobStatus = useJobStatus()

    return (
        <div className="JobStatusPage">
            <h1 className="mb-2">Document Translation Status</h1>

            { jobData ? (
                <>
                    <StatusSteps status={jobStatus} />
                    <JobSummary jobData={jobData} status={jobStatus} />
                </>
            ) : (
                <Spinner />
            )}
        </div>
    )
}

export default StatusPage
