/* eslint-disable eqeqeq */
import React, { PureComponent } from "react";
import { Link, useParams, Navigate } from "react-router-dom";
import {
    Row,
    Col,
    Button,
    CardHeader,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import {
    faRedo,
    faPlusCircle,
    faExclamationTriangle,
    faInfoCircle,
    faEye,
    faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { axios } from "../../../../config";
import {
    browserStorageHelper,
    commonHelpers,
    encryptionHelper,
} from "../../../../helpers";
import { AlertComponent } from "../../../../components";

/* eslint-disable-next-line react/jsx-props-no-spreading */
function withParams(Component) {
    return function (props) {
        return <Component {...props} params={useParams()} />;
    };
}

class EditFacilityCredentials extends PureComponent {
    constructor() {
        super();
        this.state = {
            facilityId: "",
            ehrType: "",
            ehrClientId: "",
            ehrClientSecret: "",
            ehrSystemClientId: "",
            ehrSystemClientSecret: "",
            ehrAuthorizeEndpoint: "",
            ehrTokenEndpoint: "",
            ehrFhirEndpoint: "",
            ehrAudienceEndpoint: "",
            ehrMetadataEndpoint: "",
            responseType: "",
            redirectUrl: "/admin/facilities",
            propioClientId: "",
            propioClientPassword: "",
            passwordType: "password",
            msg: {
                msgLoading: "",
                msgError: "",
                msgSuccess: "",
                msgWarning: "",
            },
        };
        this.renderRedirect = this.renderRedirect.bind(this);
        this.validator = new SimpleReactValidator({
            className: "text-danger",
            element: (message, className) => (
                <div className={className || "text-danger font-weight-bold"}>
                    <FontAwesomeIcon icon={faExclamationTriangle} size="1x" />{" "}
                    {message}
                </div>
            ),
            autoForceUpdate: this,
            messages: {
                required: "Please enter a valid :attribute.",
            },
        });
    }

    componentDidMount = async () => {
        const self = this;
        const { facilityId } = this.props.params;
        if (typeof facilityId === "undefined") {
            const redirect = true;
            const redirectUrl = self.state.redirectUrl;
            self.setState({ redirect, redirectUrl });
        }
        self.setState({
            facilityId,
            msg: commonHelpers.setMsg("Fetching data...", "", "", ""),
        });

        const token = browserStorageHelper.getItem("token");
        const uid = browserStorageHelper.getItem("uid");

        const axiosOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}/admin/facilities/${facilityId}/credentials?user_id=${uid}`,
                axiosOptions
            )
            .then((res) => {
                let facilityCredentials = res.data.result.rows;
                self.setState({
                    msg: commonHelpers.setMsg("", "", "", ""),
                });
                if (facilityCredentials !== null) {
                    self.setState({
                        ehrType: facilityCredentials.ehr_type,
                        ehrClientId:
                            facilityCredentials.ehr_client_id === null
                                ? ""
                                : facilityCredentials.ehr_client_id,
                        ehrClientSecret:
                            facilityCredentials.ehr_client_secret === null
                                ? ""
                                : facilityCredentials.ehr_client_secret,
                        ehrSystemClientId:
                            facilityCredentials.ehr_system_client_id === null
                                ? ""
                                : facilityCredentials.ehr_system_client_id,
                        ehrSystemClientSecret:
                            facilityCredentials.ehr_system_client_secret ===
                            null
                                ? ""
                                : facilityCredentials.ehr_system_client_secret,
                        ehrAuthorizeEndpoint:
                            facilityCredentials.ehr_authorize_endpoint === null
                                ? ""
                                : facilityCredentials.ehr_authorize_endpoint,
                        ehrTokenEndpoint:
                            facilityCredentials.ehr_token_endpoint === null
                                ? ""
                                : facilityCredentials.ehr_token_endpoint,
                        ehrFhirEndpoint:
                            facilityCredentials.ehr_fhir_endpoint === null
                                ? ""
                                : facilityCredentials.ehr_fhir_endpoint,
                        ehrAudienceEndpoint:
                            facilityCredentials.ehr_audience_endpoint === null
                                ? ""
                                : facilityCredentials.ehr_audience_endpoint,
                        ehrMetadataEndpoint:
                            facilityCredentials.ehr_metadata_endpoint === null
                                ? ""
                                : facilityCredentials.ehr_metadata_endpoint,
                        propioClientId:
                            facilityCredentials.propio_client_id === null
                                ? ""
                                : facilityCredentials.propio_client_id,
                        propioClientPassword:
                            facilityCredentials.propio_client_password === null
                                ? ""
                                : facilityCredentials.propio_client_password,
                    });
                }
            })
            .catch((err) => {
                self.setState({
                    msg: commonHelpers.errMessage(err),
                });
                window.location.href = `/admin/facilities?error=${encryptionHelper.encrypt(
                    self.state.msg.msgError
                )}`;
            });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const self = this;
        if (this.validator.allValid()) {
            self.setState({
                msg: commonHelpers.setMsg(
                    "Updating facility credentials...",
                    "",
                    "",
                    ""
                ),
            });
            const token = browserStorageHelper.getItem("token");
            const userId = browserStorageHelper.getItem("uid");
            const {
                facilityId,
                ehrType,
                ehrClientId,
                ehrClientSecret,
                ehrSystemClientId,
                ehrSystemClientSecret,
                ehrAuthorizeEndpoint,
                ehrTokenEndpoint,
                ehrFhirEndpoint,
                ehrAudienceEndpoint,
                ehrMetadataEndpoint,
                propioClientId,
                propioClientPassword,
            } = this.state;
            const postData = {
                user_id: userId,
                ehr_type: ehrType,
                ehr_client_id: ehrClientId,
                ehr_client_secret: ehrClientSecret,
                ehr_system_client_id: ehrSystemClientId,
                ehr_system_client_secret: ehrSystemClientSecret,
                ehr_authorize_endpoint: ehrAuthorizeEndpoint,
                ehr_token_endpoint: ehrTokenEndpoint,
                ehr_fhir_endpoint: ehrFhirEndpoint,
                ehr_audience_endpoint: ehrAudienceEndpoint,
                ehr_metadata_endpoint: ehrMetadataEndpoint,
                propio_client_id: propioClientId,
                propio_client_password: propioClientPassword,
            };

            const axiosOptions = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            axios
                .put(
                    `${process.env.REACT_APP_API_BASE_URL}/admin/facilities/${facilityId}/credentials`,
                    postData,
                    axiosOptions
                )
                .then((res) => {
                    self.setState({
                        msg: commonHelpers.setMsg(
                            "",
                            "",
                            "Facility credentials updated successfully.",
                            ""
                        ),
                    });
                    const redirect = true;
                    const redirectUrl = self.state.redirectUrl;
                    self.setState({ redirect, redirectUrl });
                })
                .catch((err) => {
                    self.setState({
                        msg: commonHelpers.errMessage(err),
                    });
                });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    togglePassword = () => {
        if(this.state.passwordType == "password") {
            this.setState({ passwordType : "text"});
           // return;
        } else {
            this.setState({ passwordType: "password" });
        }
    }

    renderRedirect = () => {
        const { redirect, redirectUrl } = this.state;
        if (redirect) {
            return <Navigate to={redirectUrl} />;
        }
    };

    handlefetchDetails = async (event) => {
        event.preventDefault();
        const self = this;
        self.setState({
            msg: commonHelpers.setMsg(
                "Fetching FHIR Server Endpoints...",
                "",
                "",
                ""
            ),
        });
        const token = browserStorageHelper.getItem("token");
        const userId = browserStorageHelper.getItem("uid");
        const axiosOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { ehrMetadataEndpoint, ehrClientId, responseType } = self.state;

        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}/admin/facilities/metadata?user_id=${userId}&metadata_endpoint=${ehrMetadataEndpoint}&response_type=${responseType}&client_id=${ehrClientId}`,
                axiosOptions
            )
            .then((res) => {
                const ehr_authorize_endpoint =
                    res.data.result.authorizeEndpoint;
                const ehr_token_endpoint = res.data.result.tokenEndpoint;
                if (
                    ehr_authorize_endpoint === null &&
                    ehr_token_endpoint === null
                ) {
                    return self.setState({
                        msg: commonHelpers.setMsg(
                            "",
                            "Error: Unable to fetch metadata details.",
                            "",
                            ""
                        ),
                    });
                }
                self.setState({
                    ehrAuthorizeEndpoint: ehr_authorize_endpoint,
                    ehrTokenEndpoint: ehr_token_endpoint,
                });
                self.setState({
                    msg: commonHelpers.setMsg(
                        "",
                        "",
                        "EHR metadata details fetched successfully.",
                        ""
                    ),
                });
            })
            .catch((err) => {
                self.setState({
                    msg: commonHelpers.errMessage(err),
                });
                self.setState({
                    ehrAuthorizeEndpoint: "",
                    ehrTokenEndpoint: "",
                });
            });
    };

    render() {
        const {
            ehrClientId,
            ehrClientSecret,
            ehrSystemClientId,
            ehrSystemClientSecret,
            ehrTokenEndpoint,
            ehrFhirEndpoint,
            ehrAudienceEndpoint,
            ehrAuthorizeEndpoint,
            ehrMetadataEndpoint,
            propioClientId,
            propioClientPassword,
            msg,
        } = this.state;

        return (
            <div>
                {this.renderRedirect()}
                <div className="container-fluid mt-3">
                    <Row className="py-3">
                        <Col md="12">
                            <Card>
                                <CardHeader className="card-header-tab z-index-6">
                                    <Row>
                                        <Col
                                            size="12"
                                            sm="9"
                                            className="font-weight-bold"
                                        >
                                            Edit Facility Credentials
                                        </Col>
                                        <Col size="12" sm="3">
                                            <Link to="/admin/facilities">
                                                <Button
                                                    className="mr-2 btn-square btn-gradient-success backBtn"
                                                    style={{ float: "right" }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faRedo}
                                                        className="fa-fw"
                                                    />{" "}
                                                    Back
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form
                                        name="frmEditFacilityCredentials"
                                        className="frmEditFacilityCredentials needs-validation"
                                        id="frmEditFacilityCredentials"
                                        onSubmit={this.handleSubmit}
                                        noValidate
                                    >
                                        <Row form>
                                            <Col md={4} sm={12}>
                                                <FormGroup>
                                                    <Label for="ehrClientId">
                                                        Ehr Client ID{" "}
                                                        <span className="rqfield">
                                                            *
                                                        </span>
                                                    </Label>
                                                    <Input
                                                        value={ehrClientId}
                                                        name="ehrClientId"
                                                        onChange={
                                                            this.changeHandler
                                                    }
                                                        type="text"
                                                        id="ehrClientId"
                                                        placeholder="Ehr Client ID"
                                                        required
                                                    />
                                                    {this.validator.message(
                                                        "ehrClientId",
                                                        ehrClientId,
                                                        "required",
                                                        {
                                                            messages: {
                                                                required:
                                                                    "Please enter a valid ehr client id.",
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} sm={12}>
                                                <FormGroup>
                                                    <Label for="ehrClientSecret">
                                                        Ehr Client Secret
                                                    </Label>
                                                    <Input
                                                        value={ehrClientSecret}
                                                        name="ehrClientSecret"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        type="textarea"
                                                        id="ehrClientSecret"
                                                        placeholder="Ehr Client Secret"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} sm={12}>
                                                <FormGroup>
                                                    <Label for="ehrSystemClientId">
                                                        EHR System App Client ID{" "}
                                                        <span className="rqfield">
                                                            *
                                                        </span>
                                                    </Label>
                                                    <Input
                                                        value={
                                                            ehrSystemClientId
                                                        }
                                                        name="ehrSystemClientId"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        type="text"
                                                        id="ehrSystemClientId"
                                                        placeholder="Ehr System App Client ID"
                                                        required
                                                    />
                                                    {this.validator.message(
                                                        "ehrSystemClientId",
                                                        ehrSystemClientId,
                                                        "required",
                                                        {
                                                            messages: {
                                                                required:
                                                                    "Please enter a valid ehr system app client id.",
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md={4} sm={12}>
                                                <FormGroup>
                                                    <Label for="ehrSystemClientSecret">
                                                        EHR System App Client
                                                        Secret
                                                    </Label>
                                                    <Input
                                                        value={
                                                            ehrSystemClientSecret
                                                        }
                                                        name="ehrSystemClientSecret"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        type="textarea"
                                                        id="ehrSystemClientSecret"
                                                        placeholder="Ehr Sytem App Client Secret"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormGroup>
                                                    <Label for="ehrAudienceEndpoint">
                                                        Ehr Audience Endpoint
                                                        <span className="rqfield">
                                                            {" "}
                                                            *
                                                        </span>
                                                    </Label>
                                                    <Input
                                                        value={
                                                            ehrAudienceEndpoint
                                                        }
                                                        name="ehrAudienceEndpoint"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        type="textarea"
                                                        id="ehrAudienceEndpoint"
                                                        placeholder="Ehr Audience Endpoint"
                                                        required
                                                    />
                                                    {this.validator.message(
                                                        "ehrAudienceEndpoint",
                                                        ehrAudienceEndpoint,
                                                        "required|url",
                                                        {
                                                            messages: {
                                                                required:
                                                                    "Please enter a valid ehr audience  endpoint.",
                                                                url: "Please enter a valid ehr audience  endpoint.",
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormGroup>
                                                    <Label for="ehrFhirEndpoint">
                                                        Ehr FHIR Endpoint
                                                        <span className="rqfield">
                                                            {" "}
                                                            *
                                                        </span>
                                                    </Label>
                                                    <Input
                                                        value={ehrFhirEndpoint}
                                                        name="ehrFhirEndpoint"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        type="textarea"
                                                        id="ehrFhirEndpoint"
                                                        placeholder="Ehr FHIR Endpoint"
                                                        required
                                                    />
                                                    {this.validator.message(
                                                        "ehrFhirEndpoint",
                                                        ehrFhirEndpoint,
                                                        "required|url",
                                                        {
                                                            messages: {
                                                                required:
                                                                    "Please enter a valid ehr fhir  endpoint.",
                                                                url: "Please enter a valid ehr fhir  endpoint.",
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form></Row>
                                        <Row form>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormGroup>
                                                    <Label for="propioClientId">
                                                        Propio Client Id
                                                        <span className="rqfield">
                                                            {" "}
                                                            *
                                                        </span>
                                                    </Label>
                                                    <Input
                                                        value={propioClientId}
                                                        name="propioClientId"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        type="text"
                                                        id="propioClientId"
                                                        placeholder="Propio Client Id"
                                                    />
                                                    {this.validator.message(
                                                        "propioClientId",
                                                        propioClientId,
                                                        "required",
                                                        {
                                                            messages: {
                                                                required:
                                                                    "Please enter a valid propio client id.",
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormGroup>
                                                    <Label for="propioClientPassword">
                                                        Propio Client Password
                                                        <span className="rqfield">
                                                            {" "}
                                                            *
                                                        </span>
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            value={
                                                                propioClientPassword
                                                            }
                                                            name="propioClientPassword"
                                                            onChange={
                                                                this
                                                                    .changeHandler
                                                            }
                                                            type={
                                                                this.state
                                                                    .passwordType
                                                            }
                                                            id="propioClientPassword"
                                                            placeholder="Propio Client Password"
                                                        />
                                                        <InputGroupText>
                                                            {this.state
                                                                .passwordType ==
                                                            "password" ? (
                                                                <FontAwesomeIcon
                                                                    icon={faEye}
                                                                    className="fa-fw"
                                                                    onClick={
                                                                        this
                                                                            .togglePassword
                                                                    }
                                                                />
                                                            ) : (
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEyeSlash
                                                                    }
                                                                    className="fa-fw"
                                                                    onClick={
                                                                        this
                                                                            .togglePassword
                                                                    }
                                                                />
                                                            )}
                                                        </InputGroupText>
                                                    </InputGroup>
                                                    {this.validator.message(
                                                        "propioClientPassword",
                                                        propioClientPassword,
                                                        "required",
                                                        {
                                                            messages: {
                                                                required:
                                                                    "Please enter a valid propio client password.",
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col lg={12}>
                                                <hr />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col lg={12}>
                                                <p className="text-muted">
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        size="1x"
                                                        className="fa-fw"
                                                    />{" "}
                                                    Either EHR Metadata Endpoint
                                                    or EHR Authorize and Token
                                                    Endpoints are required.
                                                </p>
                                            </Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormGroup>
                                                    <Label for="ehrMetadataEndpoint">
                                                        Ehr Metadata Endpoint
                                                    </Label>
                                                    <Input
                                                        value={
                                                            ehrMetadataEndpoint
                                                        }
                                                        name="ehrMetadataEndpoint"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        type="textarea"
                                                        id="ehrMetadataEndpoint"
                                                        placeholder="Ehr Metadata Endpoint"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <Button
                                                    className="mb-2 mr-2 btn-square btn-gradient-success btn-wrap"
                                                    onClick={
                                                        this.handlefetchDetails
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPlusCircle}
                                                        className="fa-fw"
                                                    />{" "}
                                                    Fetch FHIR Server Endpoints
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormGroup>
                                                    <Label for="ehrAuthorizeEndpoint">
                                                        Ehr Authorization
                                                        Endpoint{" "}
                                                        <span className="rqfield">
                                                            *
                                                        </span>
                                                    </Label>
                                                    <Input
                                                        value={
                                                            ehrAuthorizeEndpoint
                                                        }
                                                        name="ehrAuthorizeEndpoint"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        type="textarea"
                                                        id="ehrAuthorizeEndpoint"
                                                        placeholder="Ehr Authorization Endpoint"
                                                        required
                                                    />
                                                    {this.validator.message(
                                                        "ehrAuthorizeEndpoint",
                                                        ehrAuthorizeEndpoint,
                                                        "required|url",
                                                        {
                                                            messages: {
                                                                required:
                                                                    "Please enter a valid ehr authorization endpoint.",
                                                                url: "Please enter a valid ehr authorization endpoint.",
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col lg={4} md={12} sm={12}>
                                                <FormGroup>
                                                    <Label for="ehrTokenEndpoint">
                                                        Ehr Token Endpoint{" "}
                                                        <span className="rqfield">
                                                            *
                                                        </span>
                                                    </Label>
                                                    <Input
                                                        value={ehrTokenEndpoint}
                                                        name="ehrTokenEndpoint"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        type="textarea"
                                                        id="ehrTokenEndpoint"
                                                        placeholder="Ehr Token Endpoint"
                                                        required
                                                    />
                                                    {this.validator.message(
                                                        "ehrTokenEndpoint",
                                                        ehrTokenEndpoint,
                                                        "required|url",
                                                        {
                                                            messages: {
                                                                required:
                                                                    "Please enter a valid ehr token endpoint.",
                                                                url: "Please enter a valid ehr token endpoint.",
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row form>
                                            <Col
                                                size="12"
                                                lg="12"
                                                className="mt-4"
                                            >
                                                <FormGroup check row>
                                                    <Col
                                                        sm={12}
                                                        className="text-right"
                                                    >
                                                        <Button className="mb-2 mr-2 btn-square btn-gradient-success">
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faPlusCircle
                                                                }
                                                                className="fa-fw"
                                                            />{" "}
                                                            Submit
                                                        </Button>
                                                    </Col>
                                                </FormGroup>
                                                <AlertComponent msg={msg} />
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default withParams(EditFacilityCredentials);
