/* eslint-disable eqeqeq */
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    CardHeader,
    Card,
    CardBody,
    Label,
    Input,
    Button,
    Form,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import DataTable from "react-data-table-component";
import {
    faPlusCircle,
    faEdit,
    faSearch,
    faRedo,
    faKey,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { axios } from "../../../../config";
import { AlertComponent, DatatableNoData } from "../../../../components";
import { datatableConfig } from "../../../../config";
import {
    browserStorageHelper,
    commonHelpers,
    encryptionHelper,
} from "../../../../helpers";

class Facilities extends PureComponent {
    constructor() {
        super();
        this.state = {
            records: [],
            recordsCount: 0,
            sortBy: "facility_id",
            sortType: "ASC",
            datatableMsg: "No data found.",
            msg: {
                msgLoading: "",
                msgError: "",
                msgSuccess: "",
                msgWarning: "",
            },
            page: 1,
            perPage: datatableConfig.PER_PAGE_ITEMS,
            perPageOptions: datatableConfig.PER_PAGE_OPTIONS_ARR,
            searchText: "",
            enableSearch: false,
            modal: false,
            deleteRecord: {},
        };
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleSort = this.handleSort.bind(this);

        /**
         * Columns for Datatable
         */
        this.columns = [
            {
                selector: (row) => row.facility_id,
                sortField: "facility_id",
                name: "Id",
                className: "facility_id",
                center: true,
                sortable: true,
                fontWeight: 700,
                width: "100px",
                compact: true,
                fontColor: "red",
            },
            {
                selector: (row) => row.facility_name,
                sortField: "facility_name",
                name: "Name",
                className: "facility_name",
                left: true,
                sortable: true,
                width: "auto",
                wrap: true,
                compact: true,
            },
            {
                selector: (row) => row.facility_app_id,
                sortField: "facility_app_id",
                name: "App Id",
                className: "facility_app_id",
                left: true,
                sortable: true,
                width: "auto",
                wrap: true,
                compact: true,
            },
            {
                selector: (row) => row.facility_owner_name,
                sortField: "facility_owner_name",
                name: "Owner",
                className: "facility_owner_name",
                left: true,
                sortable: true,
                width: "auto",
                wrap: true,
                compact: true,
            },
            {
                selector: (row) => row.action,
                name: "Action",
                className: "action",
                width: "150px",
                center: true,
                sortable: false,
                ignoreRowClick: true,
                compact: true,
                cell: (record) => (
                    <div>
                        <Link
                            to={`/admin/edit-facility/${record.facility_id}`}
                            title="Click to edit"
                        >
                            <Button className="action-btns">
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    className="fa-fw"
                                />
                            </Button>
                        </Link>
                        <Link
                            to={`/admin/edit-facility-credentials/${record.facility_id}`}
                            title="Click to edit credentials"
                        >
                            <Button className="action-btns">
                                <FontAwesomeIcon
                                    icon={faKey}
                                    className="fa-fw"
                                />
                            </Button>
                        </Link>
                        <Button
                            className="action-btns"
                            title="Click to delete"
                            onClick={() => this.deleteModal(record)}
                        >
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="fa-fw"
                            />
                        </Button>
                    </div>
                ),
            },
        ];

        /**
         * Configuration used for Datatable
         */
        this.config = commonHelpers.getDatatableConfig(
            this.state.sortBy,
            this.state.sortType
        );
    }

    /**
     * Get initial list on component mount
     * @param {number} page Current page. Default is 1
     */
    componentDidMount = async (page = 1) => {
        const self = this;
        const { perPage } = self.state;
        self.setState({ page });
        await self.getList(page, perPage, self.state.searchText);
    };

    /**
     * Handler for page change event
     */
    handlePageChange = async (page) => {
        const self = this;
        const { perPage } = self.state;
        self.setState({ page });
        await self.getList(page, perPage, self.state.searchText);
    };

    /**
     * Handler rows for page change event
     */
    handlePerRowsChange = async (perPage, page) => {
        const self = this;
        self.setState({ page });
        await self.getList(page, perPage, self.state.searchText, true);
    };

    /**
     * Handlers for Sorting
     */
    handleSort = async (column, direction) => {
        this.setState({
            sortBy: column.sortField,
            sortType: direction.toUpperCase(),
        });
        // const { page, perPage, searchText } = this.state;
        // this.getList(
        //     page,
        //     perPage,
        //     searchText,
        //     false,
        //     column.sortField,
        //     direction.toUpperCase()
        // );
    };

    handleChangeSortBy = (sortBy) => {
        this.setState({ sortBy });
    };

    handleChangeSortType = (sortType) => {
        this.setState({ sortType });
    };

    /**
     * Handler for search field clear button event
     */
    handleClearSearch() {
        this.setState({ searchText: "" });
        const { enableSearch } = this.state;
        if (enableSearch === true) {
            window.location.reload();
        }
    }

    /**
     * Handler for search field change event
     */
    handleSearchTextChange = async (e) => {
        const self = this;
        const searchText = e.target.value;
        self.setState({
            msg: self.msgBlank,
            searchText: searchText,
        });
        self.setState({
            page: 1,
            enableSearch: true,
        });
        const { perPage } = self.state;
        const page = 1;
        const paginationReset = true;
        await self.getList(page, perPage, searchText, paginationReset);
    };

    /**
     * Reuseable function to get list
     *
     * @param {number} page Page number
     * @param {number} perPage Rows per page
     * @param {string} searchText Search text
     */
    getList(
        page = 1,
        perPage = this.state.perPage,
        searchText = "",
        paginationReset,
        sortBy,
        sortType
    ) {
        const self = this;
        self.setState({
            msg: commonHelpers.setMsg("Fetching the list...", "", "", ""),
        });
        const token = browserStorageHelper.getItem("token");
        const userId = browserStorageHelper.getItem("uid");
        sortBy = sortBy || this.state.sortBy;
        sortType = sortType || this.state.sortType;
        const axiosOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };

        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}/admin/facilities?user_id=${userId}&search=${searchText}&page=${page}&per_page=${perPage}&sort_by=${sortBy}&sort_type=${sortType}`,
                axiosOptions
            )
            .then((res) => {
                if (paginationReset) {
                    commonHelpers.resetDatatablePagination();
                }
                self.setState({
                    records: res.data.result.rows,
                    recordsCount: res.data.result.count,
                    msg: commonHelpers.setMsg("", "", "", ""),
                    currentPage: page,
                    perPage,
                });

                let error = window.location.search.split("?error=")[1];
                var decryptedData = encryptionHelper.decrypt(error);
                if (
                    decryptedData != undefined &&
                    decryptedData != "" &&
                    decryptedData != null
                ) {
                    self.setState({
                        msg: commonHelpers.setMsg("", decryptedData, "", ""),
                    });
                }
            })
            .catch((err) => {
                const msgErr = commonHelpers.errMessage(err);
                self.setState({
                    records: [],
                    recordsCount: 0,
                    msg: msgErr,
                });
            });
    }
    /**
     * Delete facility
     *
     * @param {object} record facility list
     */
    onDelete = async (deleteRecord) => {
        const self = this;
        const facilityId = deleteRecord.facility_id;
        self.setState({
            msg: commonHelpers.setMsg("Deleting facility...", "", "", ""),
        });
        const token = browserStorageHelper.getItem("token");
        const userId = browserStorageHelper.getItem("uid");
        const axiosOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        axios
            .delete(
                `${process.env.REACT_APP_API_BASE_URL}/admin/facilities/${facilityId}?user_id=${userId}`,
                axiosOptions
            )
            .then(async (res) => {
                self.setState({
                    msg: commonHelpers.setMsg(
                        "",
                        "",
                        "Deleted successfully.",
                        ""
                    ),
                    modal: !self.state.modal,
                });
                const { perPage, page } = self.state;
                self.getList(page, perPage, self.state.searchText);
            })
            .catch((err) => {
                self.setState({
                    msg: commonHelpers.errMessage(err),
                    modal: !self.state.modal,
                });
            });
    };

    handleToggleChange = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };
    deleteModal = async (record) => {
        const self = this;
        self.setState({
            modal: true,
            deleteRecord: record,
        });
    };
    render() {
        const {
            striped,
            highlightOnHover,
            responsive,
            noHeader,
            className,
            customTheme,
        } = this.config;
        const {
            searchText,
            enableSearch,
            records,
            datatableMsg,
            perPage,
            perPageOptions,
            recordsCount,
            msg,
            modal,
            deleteRecord,
        } = this.state;

        return (
            <div>
                <div className="container-fluid mt-3">
                    <Row className="py-3">
                        <Col md="12">
                            <Card>
                                <CardHeader className="card-header-tab z-index-6">
                                    <Row>
                                        <Col
                                            size="12"
                                            lg="10"
                                            className="font-weight-bold"
                                        >
                                            All Facilities
                                        </Col>
                                        <Col size="12" lg="2">
                                            <Link to="/admin/add-facility">
                                                <Button
                                                    className="mr-2 btn-square btn-gradient-success backBtn"
                                                    style={{ float: "right" }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPlusCircle}
                                                        className="fa-fw"
                                                    />{" "}
                                                    Add New
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col
                                            size="12"
                                            sm="6"
                                            lg="9"
                                            className="text-left"
                                        >
                                            {" "}
                                            <AlertComponent msg={msg} />
                                        </Col>
                                        <Col size="6" lg="3" className="mb-2">
                                            <Form>
                                                <FormGroup
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <Label for="searchText">
                                                        <FontAwesomeIcon
                                                            icon={faSearch}
                                                            className="fa-fw mr-2"
                                                        />
                                                        &nbsp;
                                                    </Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="searchText"
                                                        placeholder="Search For:"
                                                        aria-label="Search For:"
                                                        value={searchText}
                                                        onChange={
                                                            this
                                                                .handleSearchTextChange
                                                        }
                                                        autoComplete="false"
                                                    />
                                                    {enableSearch === true ? (
                                                        <Button
                                                            className="ml-2 mb-2 btn-square btn btn-danger btn-sm"
                                                            onClick={
                                                                this
                                                                    .handleClearSearch
                                                            }
                                                            title="Click to reset search filter if applied."
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faRedo}
                                                                className="fa-fw"
                                                            />
                                                        </Button>
                                                    ) : (
                                                        ""
                                                    )}
                                                </FormGroup>
                                            </Form>
                                            {enableSearch === true ? (
                                                <p className="text-success help-block">
                                                    <small>
                                                        <i className="fa fa-info-circle fa-fw" />{" "}
                                                        Search filter applied.
                                                    </small>
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                    </Row>
                                    <DataTable
                                        noHeader={noHeader}
                                        columns={this.columns}
                                        data={records}
                                        striped={striped}
                                        highlightOnHover={highlightOnHover}
                                        responsive={responsive}
                                        noDataComponents={
                                            <DatatableNoData
                                                msg={datatableMsg}
                                                columns={this.columns}
                                            />
                                        }
                                        onSort={(column, sortDirection) => {
                                            this.handleSort(
                                                column,
                                                sortDirection
                                            );
                                        }}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={recordsCount}
                                        onChangeRowsPerPage={
                                            this.handlePerRowsChange
                                        }
                                        onChangePage={this.handlePageChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={
                                            perPageOptions
                                        }
                                        className={className}
                                        customStyles={customTheme}
                                    />
                                    <Modal
                                        isOpen={modal}
                                        toggle={this.handleToggleChange}
                                        size="lg"
                                    >
                                        <ModalHeader
                                            toggle={this.handleToggleChange}
                                        >
                                            Delete facility
                                        </ModalHeader>
                                        <ModalBody>
                                            Are you sure you want to delete{" "}
                                            <b>
                                                {deleteRecord.facility_name ||
                                                    ""}
                                            </b>
                                            <br />
                                            <div className="modal-btn-wrap">
                                                <Button
                                                    className="mb-2 mr-2 btn-square action-btns"
                                                    onClick={() =>
                                                        this.onDelete(
                                                            deleteRecord
                                                        )
                                                    }
                                                >
                                                    Ok
                                                </Button>
                                                <Button
                                                    className="mb-2 mr-2 btn-square cancel-btn"
                                                    onClick={
                                                        this.handleToggleChange
                                                    }
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Facilities;
