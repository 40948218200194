import React from "react";
import { NavLink } from "react-router-dom";
import * as Feather from "react-feather";

import NavBadge from "./NavBadge";

function NavSingleItem({ navitem }) {
    const Icon =
        navitem.icon && Feather[navitem.icon] ? Feather[navitem.icon] : null;
    if (navitem.external) {
        const rel =
            navitem.target && navitem.target === "_blank"
                ? "noopener noreferrer"
                : null;

        return (
            <li className="nav-item">
                <a href={navitem.url} target={navitem.target} rel={rel}>
                    {navitem.icon && Icon && <Icon className="side-nav-icon" />}
                    <span className="nav-item-label">{navitem.name}</span>
                    {navitem.badge && (
                        <NavBadge
                            color={navitem.badge.variant}
                            text={navitem.badge.text}
                        />
                    )}
                </a>
            </li>
        );
    }
    // Force relative URLs to start with a slash
    const url = navitem.url.charAt(0) === "/" ? navitem.url : `/${navitem.url}`;

    return (
        <li className="nav-item">
            <NavLink to={url} activeclassname="active">
                {navitem.icon && Icon && <Icon className="side-nav-icon" />}
                <span className="nav-item-label">{navitem.name}</span>
                {navitem.badge && (
                    <NavBadge
                        color={navitem.badge.variant}
                        text={navitem.badge.text}
                    />
                )}
            </NavLink>
        </li>
    );
}

export default NavSingleItem;
