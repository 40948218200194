import { useContext, useState, useEffect } from "react"
import { axios } from '../../../config'
import TranslationContext from "./TranslationContext"

export const useServerGetData = (url) => {
    const { bearerToken } = useContext(TranslationContext)
    const [ loadedUrl, setLoadedUrl ] = useState(null)
    const [ data, setData ] = useState(null)

    useEffect(() => {
        if (url != loadedUrl) {
            setLoadedUrl(url)

            axios.get(url, { headers: { 'Authorization': bearerToken }}).then(({ data }) => {
                setData(data)
            }).catch(ex => {
                console.error('error', bearerToken, ex)
            })
        }
    }, [ url, loadedUrl, setLoadedUrl, setData ])

    return data
}

export const useJobData = () => {
    const { jobId } = useContext(TranslationContext)
    return useServerGetData(`/translation/job/${jobId}`)
}

export const useJobStatus = () => {
    const { jobId } = useContext(TranslationContext)
    return useServerGetData(`/translation/job/${jobId}/status`)
}
