/* eslint-disable */
import React, { PureComponent } from "react";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import { browserStorageHelper, commonHelpers } from "../../../../helpers";
import { axios } from "../../../../config";

class Layout extends PureComponent {
    constructor() {
        super();
        this.state = {
            msg: {
                msgLoading: "",
                msgError: "",
                msgSuccess: "",
                msgWarning: "",
            },
            redirect: false,
            redirectUrl: "/admin/login",
        };
        this.renderRedirect = this.renderRedirect.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    onLogout = async () => {
        const self = this;
        const token = browserStorageHelper.getItem("token");
        const axiosOptions = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/admin/logout`,{},axiosOptions
            )
            .then(() => {
                const redirect = true;
                const redirectUrl = self.state.redirectUrl;
                self.setState({ redirect, redirectUrl });
                browserStorageHelper.clear();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    renderRedirect = () => {
        const { redirect, redirectUrl } = this.state;
        if (redirect) {
            return <Navigate to={redirectUrl} />;
        }
    };

    render() {
        return (
            <div>
                {this.renderRedirect()}
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                        <FontAwesomeIcon icon={faUser} size="1x" /> admin
                    </DropdownToggle>
                    <DropdownMenu end>
                        <DropdownItem onClick={this.onLogout}>
                            <FontAwesomeIcon icon={faSignOutAlt} size="1x" />{" "}
                            <span>Logout</span>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        );
    }
}

export default Layout;
