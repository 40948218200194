/* eslint-disable */
import CryptoJS from "crypto-js";

const ENCRYPTION_KEY = "x@lKm5M2mFbCF5Ffo0eznrfdwPFffWWM";

var encryptionHelper = {
    /**
     * Encrypt string.
     */
    encrypt: (text) => {
        var encryptedData;
        if (text != undefined) {
            encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify(text),
                "key"
            ).toString();
        }

        return ENCRYPTION_KEY + ":" + encryptedData;
    },
    /**
     * Decrypt string.
     */
    decrypt: (text) => {
        var decryptedData;
        if (text != undefined) {
            let textParts = text.split(":");
            if (textParts[0] != ENCRYPTION_KEY) {
                console.log("Data is not decryptable!");
                return (decryptedData = null);
            }
            var bytes = CryptoJS.AES.decrypt(textParts[1], "key");
            decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            return decryptedData;
        }
    },
};

export default encryptionHelper;
