import { useContext, useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { decodeJwt } from 'jose'


import { axios } from '../../../config'
import Spinner from "./Spinner"
import TranslationContext from "./TranslationContext"

const LaunchPage = ({ }) => {

    const { token, target } = useParams()
    const navigate = useNavigate()
    const [ fetchedToken, setFetchedToken ] = useState(null)
    const { updateContext } = useContext(TranslationContext)

    useEffect(() => {
        if (token != fetchedToken) {
            setFetchedToken(token)

            axios.post('/translation/otp', { token }).then(({ data }) => {
                const claims = decodeJwt(data)
                if (!claims?.jobId) {
                    throw new Error('Invalid bearer token')
                }

                updateContext({
                    bearerToken: `Bearer ${data}`,
                    jobId: claims.jobId,
                })

                navigate(`/translation/${target}`)

            }).catch(ex => {
                console.error('Invalid OTP', token, ex)
                navigate('/')
            })
        }

    }, [ token, target, fetchedToken ])

    return <Spinner />
}

export default LaunchPage
