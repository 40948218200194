/* eslint-disable */
import { v1 as uuidv1, v3 as uuidv3, v4 as uuidv4, v5 as uuidv5 } from "uuid";

var commonHelper = {
    /**
     * Set error messages to be handled in react.
     *
     * @param {string} loading Loading Message.
     * @param {string} error Error Message.
     * @param {string} success Success Message.
     * @param {string} warning Warning Message.
     *
     * @returns {array} Message array containing msgLoadiing & msgError.
     */
    setMsg: function (loading = "", error = "", success = "", warning = "") {
        let msg = {
            msgLoading: loading,
            msgError: error,
            msgSuccess: success,
            msgWarning: warning,
        };
        return msg;
    },
    /**
     * Generate error message for exception caught in try-catch block.
     *
     * @param {object} err Error object caught in try-catch block.
     *
     * @returns {string} Error Message Json.
     */
    errMessage: function (err) {
        let errorMsg = "Network Error";
        if (
            err.message != "Network Error" &&
            typeof err.response != "undefined" &&
            typeof err.response.data != "undefined" &&
            typeof err.response.data.code != "undefined"
        ) {
            errorMsg = err.response.data.error;
        }
        let msgErr = this.setMsg("", errorMsg, "", "");
        return msgErr;
    },
    /**
     * Generate token based on RFC4122 standards.
     *
     * @returns UUID based on RFC4122 standards.
     */
    generateUUID: function (version = 4) {
        let generatedId = null;
        if (version == 1) {
            generatedId = uuidv1();
        }
        if (version == 3) {
            generatedId = uuidv3();
        }
        if (version == 4) {
            generatedId = uuidv4();
        }
        if (version == 5) {
            generatedId = uuidv5();
        }
        return generatedId;
        /** */
    },
    /**
     * Generate Request token to be passed with every request to map request with response.
     *
     * @returns Request Token.
     */
    generateRequestToken: function (sessionId = 0) {
        let uuid = this.generateUUID();
        let requestToken = uuid;
        if (sessionId != 0) {
            requestToken = sessionId + "-0-" + this.generateUUID();
        }
        return requestToken;
    },
    /**
     * Get datatable configuration.
     *
     * @param {string} sortColumnColunm on which default sort will apply. Default is 'id' column.
     * @param {string} sortOrder Sorting order for default sort. Default is asc.
     * @param {boolean} tblStriped stripe color the odd rows. Default is true.
     * @param {boolean} tblHighlightOnHover if rows are to be highlighted on hover. Default is true.
     * @param {boolean} tblResponsive makes the table horizontally scrollable on smaller screen widths. Default is true.
     * @param {string} tblHeader removes the table header. title, contextTitle and contextActions will be ignored. Default is true.
     * @param {string} tblClassName override the className on the Table wrapper.
     * @param {object} tblCustomTheme Override the default theme, by overriding specifc props. Your changes will be merged. See Theming for more information.
     *
     * @returns Datatable configuration.
     */
    getDatatableConfig: (
        sortColumn = "id",
        sortOrder = "asc",
        tblStriped = true,
        tblHighlightOnHover = true,
        tblResponsive = true,
        tblHeader = true,
        tblClassName = "table table-striped table-bordered table-hover table-condensed table-responsive"
    ) => {
        const tblCustomTheme = {
            headCells: {
                style: {
                    fontSize: "15px",
                    fontWeight: "800",
                },
            },
        };

        var config = {
            sort: { column: sortColumn, order: sortOrder },
            striped: tblStriped,
            highlightOnHover: tblHighlightOnHover,
            responsive: tblResponsive,
            noHeader: tblHeader,
            className: tblClassName,
            customTheme: tblCustomTheme,
        };
        return config;
    },
    /**
     * Reset datatable pagination to 1st page.
     *
     * @param {string} btnId Button selector.
     */
    resetDatatablePagination: (btnId = "pagination-first-page") => {
        document.getElementById(btnId).dispatchEvent(new Event("click"));
    },
    /**
     * Log error message to console.
     *
     * @param {string} msg Error Message.
     * @param {object} err Actual error stack object.
     *
     * @returns Logs error message to console. Returns nothing.
     */
    logError: (msg = "Something went wrong.", err = null) => {
        // eslint-disable-next-line no-console
        console.log(
            "\n----------------\nError Start\n----------------\nTimestamp: " +
                new Date() +
                "\n\n" +
                msg +
                "\n" +
                err +
                "\n----------------\nError End\n----------------\n"
        );
    },
    /**
     * Log generat message to console.
     *
     * @param {string} msg General Message.
     *
     * @returns Logs generat message to console. Returns nothing.
     */
    logMessage: (msg = "Task completed successfully.") => {
        // eslint-disable-next-line no-console
        console.log(
            "\n----------------\nMessage Start\n----------------\nTimestamp: " +
                new Date() +
                "\n\n" +
                msg +
                "\n----------------\nMessage End\n----------------\n"
        );
    },
};

export default commonHelper;
