import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { browserStorageHelper } from "../helpers";

const AuthGuard = () => {
    const location = useLocation();
    const token = browserStorageHelper.getItem("token");

    if (!token) {
        return <Navigate to="/admin/login" state={{ from: location }} replace />;
    }

    return <Outlet />;
};

export default AuthGuard;
